import React, {useEffect, useMemo, useRef} from "react"
import styled from 'styled-components'
import Layout from '../components/layout';
import RowWrapper from '../utils/rowWrapper';
import { Link } from 'gatsby'
import * as t from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import texture from '../images/textures/9.png'

// import Atropos from 'atropos';

import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

import X from '../images/x.svg'


import HeroBg from '../images/heroBg-copy.svg';
import Clip from '../images/hero-clipping.svg';
import HeroImg from '../images/hero-graphic.png';
import Personalized from '../images/personalized.svg';
import Job from '../images/job-done.svg';
import Adventure from '../images/adventure.svg';
import AboutFloatR from '../images/about-bg-r.svg';
import AboutFloatL from '../images/about-bg-l.svg';
import TestimonialBg from '../images/testimonial-bg.svg';

import TestimonialCard from '../components/testimonials/Card'

// testimonial images
import DwLogo from '../images/logo-black.png'
import CelebrandLogo from '../images/celebrand-logo.png'


import Services from '../components/services/service';
import Tech from '../components/technologies/tech'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const CanvasContainer = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  left: 0;
`

const Hero = styled.section`
  position: relative;
  height: 100vh;
  background-image: url(${props => props.bg});
  background-size: cover;

  @media ${props => props.theme.breaks.down('md')} {
    height: auto;
  }
`

const Canvas = styled.canvas`
  position: absolute;

`

const Clipping = styled.div`
  position: absolute;
  height: 20rem;
  width: 100%;
  bottom: -2px;
  left: 0;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${props => props.theme.breaks.down('sm')} {
    height: 10rem;
  }
`

const HeroImage = styled.div`
  position: relative;
  width: 50%;
  padding: 0 1rem;

  .hero-image {
    display: block;
    margin: 0 auto;
    margin-top: 3rem;
    width: 85%;
    position: relative;
    visibility: hidden;
    z-index: 2;

    @media ${props => props.theme.breaks.down('lg')} {
    width: 95%;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 65%;
    margin: 0 auto;
    min-height: 30rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 95%;
    margin: 0 auto;
  }

`

const HeroText = styled.div`
  margin-top: 10rem;
  padding: 0 1rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  /* justify-content: center; */
  /* align-items: center; */

  @media ${props => props.theme.breaks.down('lg')} {
    margin-top: 6rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 95%;
    margin-bottom: 20rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    justify-content: center;
    align-items: center;
    margin-bottom: 15rem;
  }
    

  h1, p, span {
    color: #fff;
  }
  
  h1 {
    font-size: 6rem;
    font-weight: 700;
    line-height: 7rem;
    /* max-width: 55rem; */

    @media ${props => props.theme.breaks.down('lg')} {
      font-size: 5rem;
    }

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 4.5rem;
      line-height: 5rem;
      text-align: center;
    }

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  p {
    margin-top: 4rem;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 3rem;
    margin-bottom: 8rem;
    max-width: 50rem;

    @media ${props => props.theme.breaks.down('lg')} {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 1.6rem;
      text-align: center;
    }
  }
`

const HeroContent = styled(RowWrapper)`
  display: flex;
  justify-content: space-between;
  /* padding: 0 2rem; */
  padding-top: 5rem;
  height: 100%;

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`

const ContactButton = styled(Link)`
  background-color: ${props => props.theme.palette.secondary.main};
  color: #fff;
  display: block;
  width: 20rem;
  height: 6rem;
  text-align: center;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding: 2rem 0;
  border-radius: 5px;
  /* margin: 0 auto; */

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.6rem;
  }
`

const About = styled.div`
  margin-top: 2rem;
  position: relative;

    h3 {
      font-size: 4rem;
      text-align: center;
      color: ${props => props.theme.colors.heading3};

      @media ${props => props.theme.breaks.down('sm')} {
        font-size: 3rem;
      }

    }

    h3.stand-out {
      margin-top: 10rem;
    }

    p {
      text-align: center;
      width: 50%;
      margin: 3rem auto;
      font-size: 1.8rem;
      line-height: 150%;
      color: ${props => props.theme.colors.darkText};

      @media ${props => props.theme.breaks.down('sm')} {
        width: 75%;
        font-size: 1.6rem;
        line-height: 2.6rem;
      }

      @media ${props => props.theme.breaks.down('smp')} {
        width: 85%;
      }

    }

    @media ${props => props.theme.breaks.down('sm')} {
      margin-top: 1rem;
      padding-bottom: 8rem;
    }
`

const AboutFloaterR = styled.div`
  width: 18rem;
  height: 40rem;
  background-image: url(${props => props.src});
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;

  img {
    width: 100%;
  }

  @media ${props => props.theme.breaks.down('md')} {
    /* right: -10rem; */
    height: 30rem;
    display: none;
  }
  
`
const AboutFloaterL = styled.div`
  width: 23rem;
  height: 23rem;
  background-image: url(${AboutFloatL});
  position: absolute;
  top: 10%;
  left: -10rem;
  background-size: cover;
  background-position: center;
  animation: 3s rotate infinite forwards;
  z-index: -1;

  @media ${props => props.theme.breaks.down('sm')} {
    left: -15rem;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    left: -20rem;
  }

`

const IconContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 2rem auto;

  @media ${props => props.theme.breaks.down('sm')} {
    flex-direction: column;
    width: 95%;
  }
`

const Point = styled.div`
  margin-top: 5rem;
  /* margin-right: 10rem; */
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    width: 20rem;

    @media ${props => props.theme.breaks.between('sm', 'lg')} {
      width: 15rem;
    }
  }

  p {
    width: 23rem;

    @media ${props => props.theme.breaks.down('md')} {
      width: 18rem;
    }

    @media ${props => props.theme.breaks.down('sm')} {
      width: 95%;
    }
  }
`

const Testimonials = styled.div`
  height: 50rem;
  background-image: url(${TestimonialBg});
  padding-top: 4rem;
  overflow: hidden;

  h3 {
    font-size: 4rem;
    text-align: center;
    color: ${props => props.theme.colors.headingLight}
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: 35rem;
    padding-top: 2rem;
    
    h3 {
      font-size: 3rem;
    }
  }

`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 6rem auto;

  @media ${props => props.theme.breaks.down('md')} {
  margin: 4rem auto;
  overflow: scroll;
  width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const Technologies = styled.div`
  position: relative;
  
  h3 {
    font-size: 4rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 6rem;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 3rem;
    }

  }
`

const ContactButtonContainer = styled.div`
  * {
    margin: 0 auto;
  }
`

const XStyles = styled.div`
  position: absolute;
  width: ${props => props.box}rem;
  height: ${props => props.box}rem;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  opacity: ${props => props.opacity};
  z-index: 1;
  /* transform-origin: center; */

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: calc(${p => p.box}rem / 1.5);
    height: calc(${p => p.box}rem / 1.5);
    top: ${p => p.mtop ? p.mtop : p.top}%;
  }
`

const IndexPage = ({ location }) => {

  const text = useRef()

  console.log(location);


  useEffect(() => {

    const common = (x, y, dur) => { return {x: x, y: y, duration: dur, repeat: -1, yoyo: true}}

    const xAnimRotation = [{rotate: 360, duration: 15}, {rotateX: 360, duration: 15}, {rotateX: 360, rotateZ: 360, duration: 20}]

      let x = gsap.timeline()

      x.addLabel("startX")
      .to('#first-x', {...common(150, -50, 25)}, "startX")
      .to('#first-x img', {...xAnimRotation[0], repeat: -1, yoyo: true}, "startX")
      .to('#second-x', {...common(-20, 50, 15)}, "startX")
      .to('#second-x img', {...xAnimRotation[1], repeat: -1, yoyo: true}, "startX")
      .to('#third-x', {...common(50, 0, 20)}, "startX")
      .to('#third-x img', {...xAnimRotation[2], repeat: -1, yoyo: true}, "startX")

    const startTl = gsap.timeline();

    startTl.addLabel("start-hero")
    .fromTo(text.current, {autoAlpha: 0, color: "transparent", scale: .8}, { color: "white", scale: 1, autoAlpha: 1, duration: 1})
    .fromTo(".hero-image", {scale: .3, autoAlpha: 0, y: -350, x: 150}, {scale: 1, autoAlpha: 1, y: 0, x: 0, duration: 1.4, ease: "back.inOut"}, "start-hero").addLabel("start-pulsate")
    .fromTo(".hero-image", {scale: 1, filter: "saturate(75%)"}, {scale: 1.02, filter: "saturate(130%) drop-shadow(1rem 1rem 4rem #00000044)", duration: 2, repeat: -1, yoyo: true}, "start-pulsate+=.5")

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.about',
        start: 'top 45%',
        toggleActions: 'play none none reverse',
        // markers: true
      }
    })

    tl.addLabel('startFade')
    .fromTo('.about-icon', {y: 80, autoAlpha: 0}, {y: 0, autoAlpha: 1, duration: 1.2, stagger: .1, ease: "ease.inOut"}, "startFade")
    .fromTo('.about-point p', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5}, "startFade")
  }, [])

  const scrollTo = (id) => {
    gsap.to(window, {scrollTo: {y: `#${id}`, offsetY: 180}, duration: 1, ease: "ease.out"})
  }

  useEffect(() => {
    if (!location.state?.tag) return;

    console.log(location.state);

    scrollTo(location.state.tag)

  }, [location.state])

  // useEffect(() => {
   

  //   /**
  //    * Base
  //    */
  //   // Canvas
  //   const canvas = document.querySelector('canvas.webgl')

  //   // Scene
  //   const scene = new t.Scene()

  //   /* TEXTURE LOADER */

  //   const textureLoader = new t.TextureLoader()
  //   const starTexture = textureLoader.load(texture)
  //   // const rainTexture = textureLoader.load('../images/textures/particles/2.png')

  //   /**
  //    * PARTICLES
  //    */

  //       const rainGeo = new t.BufferGeometry()

  //       const count = 600

  //       const positions = new Float32Array(count * 3)
  //       const colors = new Float32Array(count * 3)

  //       console.log(positions.length);

  //       for (let i = 0; i < count * 3; i++) {
  //         const i3 = i * 3

  //         positions[i3 + 0] = (Math.random() - .5) * 20
  //         positions[i3 + 1] = (Math.random() - .5) * 20
  //         positions[i3 + 2] = (Math.random() - .5) * 20

  //         // colors[i3 + 2] = Math.random()
  //         // colors[13 + 1] = .5
  //         // colors[13 + 0] = .5
  //         colors[i3 + 2] = 1
  //         colors[13 + 1] = 1
  //         colors[13 + 0] = 1
          
  //       }

  //       // const newPos = positions.map((el, i) => {
  //       //     colors[i] = Math.random()
  //       //     return (Math.random() - .5) * 20
  //       // })

  //       rainGeo.setAttribute('position',
  //           new t.BufferAttribute(positions, 3)
  //       )

  //       rainGeo.setAttribute('color',
  //           new t.BufferAttribute(colors, 3)
  //       )

  //       const rainMat = new t.PointsMaterial({
  //           size: .2,
  //           sizeAttenuation: true,
  //           transparent: true,
  //           alphaMap: starTexture,
  //           // alphaTest: .001,
  //           // color: 0x3587af,
  //           depthWrite: false, // best solution
  //           // vertexColors: true
  //           // blending: t.AdditiveBlending
  //           // depthTest: false  // great solution with nothing else there
  //       })

  //       const rain = new t.Points(rainGeo, rainMat)


  //       scene.add(rain)
  //   /**
  //    * Sizes
  //    */
  //   const sizes = {
  //       width: window.innerWidth,
  //       height: window.innerHeight
  //   }

  //   window.addEventListener('resize', () =>
  //   {
  //       // Update sizes
  //       sizes.width = window.innerWidth
  //       sizes.height = window.innerHeight

  //       // Update camera
  //       camera.aspect = sizes.width / sizes.height
  //       camera.updateProjectionMatrix()

  //       // Update renderer
  //       renderer.setSize(sizes.width, sizes.height)
  //       renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  //   })

  //   /**
  //    * Camera
  //    */
  //   // Base camera
  //   const camera = new t.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
  //   camera.position.x = 1
  //   camera.position.y = 1
  //   camera.position.z = 1
  //   scene.add(camera)

  //   // Controls
  //   const controls = new OrbitControls(camera, canvas)
  //   controls.enableDamping = true

  //   /**
  //    * Renderer
  //    */
  //   const renderer = new t.WebGLRenderer({
  //       canvas: canvas,
  //       alpha: true
  //   })
  //   renderer.setSize(sizes.width, sizes.height)
  //   renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  //   /**
  //    * Animate
  //    */
  //   const clock = new t.Clock()
  //   let flip = true;

  //   const tick = () =>
  //   {
  //       const elapsedTime = clock.getElapsedTime()

  //       // Animate particles
  //       // if (!flip) {
  //         rain.position.y = - elapsedTime * .02
  //         if (rain.position.y < -5)
  //           flip = true
  //       // } else {
  //       //   rain.position.y = elapsedTime * .02
  //       //   if (rain.position.y > 5)
  //       //     flip = false
  //       // }

  //       // Update controls
  //       controls.update()

  //       // Render
  //       renderer.render(scene, camera)

  //       // Call tick again on the next frame
  //       window.requestAnimationFrame(tick)
  //   }

  //   tick()
  // }, [])

  const FloatingX = ({box, left, top, mtop, opacity, id}) => {
    return <XStyles box={box} top={top} mtop={mtop} left={left} opacity={opacity} className="floating-x" id={id}>
      <img src={X} alt="" />
    </XStyles>
  }


  return (
    <Layout pageTitle="Novosoft" location={location.pathname}>
      <main id="home">
      <Hero bg={HeroBg}>

        <Canvas className="webgl"></Canvas>

        <HeroContent>
          <HeroText ref={text}>
            <h1>Ultimate software solution.</h1>

            <p>
            Beautiful designs, Fast delivery, Easy to use.
            We create your business website as well as your in house custom software. 
            {/* We are, developers at your service!  */}
            </p>

            <ContactButton to="/contact-us">
              Contact Us
            </ContactButton>

          </HeroText>
          <HeroImage>
            <img className="hero-image" src={HeroImg} />

            <FloatingX top="13" left="4" box="3" opacity=".4" id="first-x" />
            <FloatingX top="40" mtop="50" left="95" box="2.5" opacity=".8" id="second-x" />
            <FloatingX top="55" mtop="95" left="45" box="2" opacity="1" id="third-x" />
          </HeroImage>
        </HeroContent>

        <Clipping bg={Clip}>

        </Clipping>
      </Hero>

      <About className="about" id="about">
        <h3>About Us</h3>
        <p>
        At Novosoft we are on the cutting edge of technological trends. We constantly update and improve our workflow to make sure that you get the most out of the latest advancements in software development and modern design trends. <br />
        We actively participate in your project and help you achieve your vision. We work alongside your team to identify possible challenges and present appropriate solutions. We offer constructive suggestions and help you take your ideas
        form concept to production.
        </p>

        <h3 className="stand-out">How We Stand Out?</h3>

        <IconContainer>

          <Point className="about-point">
            <img className="about-icon" src={Personalized} />
            <p>We offer personalized service. Always attentive to your specific needs, and always available to our clients. You can rest assured knowing that
              your project is getting the dedicated care it deserves.</p>
          </Point>

          <Point className="about-point">
            <img className="about-icon" src={Job} />
            <p>
            At Novosoft we pride ourselves in getting the job done quickly and efficiently while keeping our work to the highest standards.
            </p>
          </Point>

          <Point className="about-point">
            <img className="about-icon" src={Adventure} />
            <p>
            We are always excited for new adventures, and love to take on new challenges. We enjoy the thrill of tackling new problems and watching your ideas unfold until a beautiful product emerges.
            </p>
          </Point>

        </IconContainer>
        <AboutFloaterR src={AboutFloatR}>&nbsp;</AboutFloaterR>
        <AboutFloaterL>&nbsp;</AboutFloaterL>
      </About>

      {/* <Testimonials>
        <h3>Testimonials</h3>

        <CardContainer>
          <TestimonialCard name="John Doe" title="CEO at Lorem Ipsum" description="“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis condimentum mus sed sit molestie suspendisse nulla arcu elementum. Enim et mi et tempor ut fermentum rhoncus, ut arcu.”" img={DwLogo} />
          <TestimonialCard name="John Doe" title="CEO at Lorem Ipsum" description="“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis condimentum mus sed sit molestie suspendisse nulla arcu elementum. Enim et mi et tempor ut fermentum rhoncus, ut arcu.”" img={CelebrandLogo} />
          <TestimonialCard name="John Doe" title="CEO at Lorem Ipsum" description="“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis condimentum mus sed sit molestie suspendisse nulla arcu elementum. Enim et mi et tempor ut fermentum rhoncus, ut arcu.”" img={DwLogo} />
        </CardContainer>
      </Testimonials> */}

      <Services id="services">
        <ContactButton to="/contact-us">
          Contact Us
        </ContactButton>
      </Services>

      <Technologies id="tech">
        <h3>Technologies</h3>

        <Tech></Tech>

        <h3>Like what you see? Contact Us?</h3>
        
        <ContactButtonContainer>
          <ContactButton to="/contact-us">
            Contact Us
          </ContactButton>
        </ContactButtonContainer>

      </Technologies>

      </main>
    </Layout>
  )
}

export default IndexPage
