import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';


 
const Container = styled.div`
 /* height: 80vh; */
 margin-top: 15rem;
 display: flex;
 flex-direction: ${props => props.reversed ? "row-reverse" : "row"};

 @media ${props => props.theme.breaks.down('md')} {
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 8rem;
 }
`

const Text = styled.div`
  h4 {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 3rem;

    .emoji {
      display: inline-block;
      padding-top: 0;
      width: 3rem;
      position: relative;
      /* top: .6rem; */
    }
  }
  flex-basis: 1;
  padding: 2rem 8rem;
  max-width: 50%;

  @media ${props => props.theme.breaks.down('md')} {
    width: 95%;
    max-width: none;
    padding: 2rem 4rem;
    margin-top: 4rem;

    h4 {
      text-align: center;
    }

    p { 
      text-align: center
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    padding: 2rem;
    h4 {
      font-size: 2.2rem;
    }
    
    p {
      font-size: 1.5rem;
    }
  }
`

const Image = styled.div`
  flex-basis: 1;
  max-width: 50%;
  /* perspective: 1500; */

  img {
    width: 60rem;
    /* -webkit-filter: drop-shadow(1rem 1rem 2rem #00000033); */
    filter: drop-shadow(1rem 1rem 2rem #00000066);
    /* filter: hue-rotate(45deg); */

    &.app {
      width: 40rem;
    }
  }

  @media ${props => props.theme.breaks.down('lg')} {
    img {
      width: 50rem;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    max-width: none;
    width: 60%;

    img {
      width: 100%;

      &.app {
      width: 100%;
    }
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 80%;
  }
`

export default ({title, text, image, reversed, imgClass}) => {

  const img = useRef()


  

 return (
 <Container reversed={reversed}>
   <Text>
     <h4>{title}</h4>
     <p>{text}</p>
   </Text>
   <Image>
    <img ref={img} className={`service-images ${imgClass}`} src={image} alt="" />
   </Image>
 </Container>
 )
}