import React, { useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import ServiceSection from './service-section';

import ServiceWeb from '../../images/service1.png'
import ServiceSoft from '../../images/service-custom.png'
import ServiceApp from '../../images/service-app.png'
import Emoji from '../../images/laughing-tears.jpg'

gsap.registerPlugin(ScrollTrigger);

 
const Container = styled.div`
 background-image: linear-gradient(to bottom, #fff, #F1F8FE);
 padding: 5rem 0;

 @media ${props => props.theme.breaks.down('md')} {
  padding: 2rem 0;
 }
`

const ServicesContainer = styled.div`
  width: 90%;
  margin: auto;

  @media ${props => props.theme.breaks.down('md')} {
    width: 95%;
  }
`

const Header = styled.h3`
  font-size: 4.5rem;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 8rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 3rem;
    margin: 2rem auto 2rem auto;
  }
    
`

const ButtonContainer = styled.div`
  width: 20rem;
  margin: 5rem auto 2rem auto;
`


export default ({children, id}) => {

    useEffect(() => {
      // gsap.fromTo(img.current, {scale: 1, filter: "drop-shadow(1rem 1rem 2rem #00000066)"}, {scale: 1.02, filter: "drop-shadow(1rem 1rem 3rem #00000033)", duration: 1, repeat: -1, yoyo: true})
  
    const images = gsap.utils.toArray(".service-images");
  
    images.forEach((cur, i) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cur,
          start: "top 60%",
          // scrub: true
          toggleActions: "play none none reverse"
        }
      })

      let x = i % 2 === 0 ? 25 : -25;

      if (window.innerWidth < 960) x = 0
  
      tl.to(cur, {x, y: -25, scale: 1.05, filter: "drop-shadow(1rem 1rem 4rem #00000022)", duration: .6})
      // .to(cur, {scale: 1, filter: "drop-shadow(1rem 1rem 2rem #00000066)", duration: .8})
    })
  
    }, [])
 
 return (
 <Container id={id}>
   <Header>Services</Header>
   <ServicesContainer>
    <ServiceSection title="Websites" text="Are you looking to create a beautiful and unique website that does justice to your brand? or perhaps you want to upgrade your existing, cookie-cutter website that does not call attention to itself?... Look no further! Novosoft will design and create a stunning modern website with smooth transitions and eye-catching animations that will leave an immediate impression on your site visitors, and with blazing fast loading times, you won’t have to sacrifice efficiency for beauty." image={ServiceWeb}></ServiceSection>

    <ServiceSection title="Custom software for your business" text={<>You have exhausted all your options. You started out with excel sheets held together with duct tape <img className='emoji' src={Emoji} />, then you tried every over the counter solution. At the end, nothing works for you. Nothing fits your exact business needs. Let us at Novosoft help you. We will help you find solutions and guide you in creating your perfect business software. At Novosoft, we understand that while you want a great looking software, above all you want a permanent business solution that is easy to use. With state of the art security built in, you will be able to control your business at the click of a button.</>} image={ServiceSoft} reversed={true}></ServiceSection>

    <ServiceSection title="App development" text="You have a new business idea, but you don’t know how to bring it to life. Enter Novosoft. With great experience in the field we will be able to guide you to a best-selling app, with both mobile and desktop support." image={ServiceApp} imgClass="app"></ServiceSection>
   </ServicesContainer>
   <ButtonContainer>{children}</ButtonContainer>
 </Container>
 )
}