import React, {useRef, useEffect, useState} from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';


import gsap from 'gsap';

import ColoredCircle from '../../images/colored-cir.svg';
import NovoInCircle from '../../images/colored-cir-novo.png'

import graph from '../../images/graphql.png'
import mongo from '../../images/mongodb-logo.png'
import socket from '../../images/socket-io-logo.png'
import reactLogo from '../../images/react.png'
import nodejs from '../../images/nodejs-logo.png'
import gatsbyLogo from '../../images/gatsby-logo.png'
import strapi from '../../images/strapi-logo-m.png';

 
const Container = styled.div`
 position: relative; 
 width: 120rem;
 margin: 0 auto;
 height: 120rem;
 overflow: hidden;
 margin-bottom: 8rem;



 @media ${props => props.theme.breaks.down('lg')} {
  max-width: 1200rem;
  width: 100%;
  /* height: 100rem; */
 }

 @media ${props => props.theme.breaks.down('md')} {
  max-width: 96rem;
  width: 100%;
  height: 100rem;
 }

 @media ${props => props.theme.breaks.down('sm')} {
  max-width: 60rem;
  width: 100%;
  height: 60rem;
 }

 @media ${props => props.theme.breaks.down('smp')} {
  height: 45rem;
 }
 
 
`

const ContainerAbs = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  transform: rotateZ(180deg);

  @media ${props => props.theme.breaks.down('lg')} {
    width: 100%;
  }
  
`

const Bubble = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left ? props.left : "auto"};
  right: ${props => props.right ? props.right : "auto"};
  width: ${props => props.rad}rem;
  height: ${props => props.rad}rem;
  border-radius: 50%;
  text-align: center;

  ${props => props.top.includes("%") &&
  css`
    transform: translateY(-50%);
  `
  }

  ${props => props.left?.includes("%") &&
  css`
    transform: translateX(-50%);
  `
  }

  ${props => props.right?.includes("%") &&
  css`
    transform: translateX(50%);
  `
  }

@media ${props => props.theme.breaks.down('md')} {
  top: calc(${p => p.top} / 1.2);
  width: calc(${p => p.rad}rem / 1.5);
  height: calc(${p => p.rad}rem / 1.5);
}

@media ${props => props.theme.breaks.down('sm')} {
  top: calc(${p => p.top} / 2);
  width: calc(${p => p.rad}rem / 2.5);
  height: calc(${p => p.rad}rem / 2.5);
}

@media ${props => props.theme.breaks.down('smp')} {
  top: calc(${p => p.top} / 2.5);
  width: calc(${p => p.rad}rem / 2.8);
  height: calc(${p => p.rad}rem / 2.8);
}

`

const ColoredBubbleStyles = styled(Bubble)`


  img {
    width: 100%;
  }
`

const GrayBubble = styled(Bubble)`
  background-color: ${props => props.theme.colors.bubble};
`

const ShadowBubble = styled(Bubble)`
  box-shadow: 0px 4px 18px 5px rgba(13, 71, 251, 0.06);

  
  &:hover {
    /* transform: scale(1.03); */
    box-shadow: 0px 4px 25px 10px rgba(13, 71, 251, 0.06);
  }
`

const ColoredBubble = ({top, left, right, rad}) => {
  return <ColoredBubbleStyles top={top} left={left} right={right} rad={rad}>
    <img src={ColoredCircle} alt="" />
  </ColoredBubbleStyles>
}

const TechImage = styled.div`

  position: relative;
  transform: rotateZ(180deg);

  .tech-image {
    width: 80%;
    margin: 0 auto;
    padding: 7rem 0;
  }

  .tech-image-bigger {
    width: 80%;
    margin: 0 auto;
    padding: 5rem 0;
  }

  .colored-cir {
    width: 92%;
    margin: 0 auto;
    padding: 1.1rem 0;
  }

  @media ${props => props.theme.breaks.down('md')} {
    .tech-image {
      padding: 4.5rem 0;
    }

    .tech-image-bigger {
      padding: 3rem 0;
    }

    .colored-cir {
      padding: .8rem 0;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    .tech-image {
      padding: 3rem 0;
    }

    .tech-image-bigger {
      padding: 2rem 0;
    }

    .colored-cir {
      padding: .5rem 0;
    }
  }

  @media ${props => props.theme.breaks.down('smp')} {
    .tech-image {
      padding: 2.5rem 0;
    }

    .tech-image-bigger {
      padding: 1.5rem 0;
    }

    .colored-cir {
      padding: .3rem 0;
    }
  }

`





const Tech = () => {

  const containerRef = useRef();

  let init = true

    let tl;
    let fastRotateDone = false

  useEffect(() => {
    
    const logos = gsap.utils.toArray(".logos");

    tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 35%",
        // toggleActions: "play none none reverse",
        // markers: true
      }
    })

    tl.addLabel("startRotate")
    .to(containerRef.current, {rotateZ: '0deg', duration: 3.5, ease: "ease.out"}, "startRotate")
    .to(logos, {rotationZ: '360deg', duration: 3.5, ease: "ease.out", onComplete: () => {
      fastRotateDone = true
    }}, "startRotate")
    .addLabel("slowRotate")
    .to(containerRef.current, {repeatDelay: 0, rotationZ: '360deg', duration: 60, repeat: -1, ease: 'none'}, "slowRotate+=1")
    .to(logos, {repeatDelay: 0, rotateZ: '-360deg', duration: 120, repeat: -1, ease: 'none'}, "slowRotate+=1")
   

  }, [])

  const stopTimeline = (id) => {
    console.log("stopping....");
    if (!fastRotateDone) return;

    if (tl)
      tl.pause()

    gsap.to(`#${id}`, {scale: 1.1, boxShadow: "0px 4px 25px 10px rgba(13, 71, 251, 0.06);", duration: .3})
  }

  const resumeTimeline = (id) => {
    console.log("resuming...");
    if (!fastRotateDone) return;

    
    if (tl)
     tl.resume()

    gsap.to(`#${id}`, {scale: 1, boxShadow: "0px 4px 18px 5px rgba(13, 71, 251, 0.06);", duration: .3})
  }

  init = false;

  const ImageBubble = ({ children,  top, left, rad, right, className, id }) => {

    return <ShadowBubble top={top} left={left} right={right} rad={rad} id={id} className={className}
      onMouseEnter={() => stopTimeline(id)} onMouseLeave={() => resumeTimeline(id)} 
      >
    
      {children}
    </ShadowBubble>
  }

  const logoContent = [
    {
      top: '12rem',
      left: '70%',
      rad: '21',
      id: 'strapi',
      src: strapi,
      imgClass: 'tech-image',
      content: 'Strapi is a headless cms. What it means for you?. With Strapi being so lightweight and fast, you will get a great user experience, at a fraction of the time of your current wordpress cms.'
    },
    {
      top: '20rem',
      left: '30%',
      rad: '21',
      id: 'graph',
      src: graph,
      imgClass: 'tech-image',
      content: 'GraphQL is the language we use to talk to your backend to get just the right amount of info to your frontend. That is great news for your mobile site which we can customize to request less data...'
    },
    {
      top: '42rem',
      right: '10%',
      rad: '21',
      id: 'nodejs',
      src: nodejs,
      imgClass: 'tech-image-bigger',
      content: 'By using one language across both our frontend and backend we minimize mistakes, are able to share code and just an overall great experience for our customers'
    },
    {
      top: '55rem',
      left: '18%',
      rad: '21',
      id: 'mongo',
      src: mongo,
      imgClass: 'tech-image',
      content: 'MongoDB is one of the most popular databases and for good reason! You can start small and be ready to scale really big right when you need it!'
    },
    {
      top: '72rem',
      right: '25%',
      rad: '21',
      id: 'react',
      src: reactLogo,
      imgClass: 'tech-image-bigger',
      content: 'React is a powerful modern framework. Did we lose you yet?.. For our customers it means you will be able to navigate your software without long loading times.'
    },
    {
      top: '85rem',
      left: '30%',
      rad: '21',
      id: 'gatsby',
      src: gatsbyLogo,
      imgClass: 'tech-image-bigger',
      content: 'We use Gatsby for your frontend. With blazing fast speeds, accessibility and SEO built in, need we say more?..'
    },
    {
      top: '45rem',
      left: '50%',
      rad: '30',
      id: 'novo-logo',
      className: 'novo-logo',
      src: NovoInCircle,
      imgClass: 'colored-cir',
      content: 'Your complete software solution!!'
    },
  ]

 return (
 <Container 
  // onMouseEnter={hoverHandler} onMouseOut={mouseOut}
 >
   <ContainerAbs ref={containerRef} >
   <ColoredBubble top="8rem" left="50%" rad="3"></ColoredBubble>
   <ColoredBubble top="45rem" left="5%" rad="3"></ColoredBubble>
   <ColoredBubble top="36rem" left="45%" rad="4.5"></ColoredBubble>
   <ColoredBubble top="35rem" left="85%" rad="5"></ColoredBubble>
   <ColoredBubble top="95rem" left="58%" rad="5"></ColoredBubble>
   <ColoredBubble top="68rem" right="8%" rad="6"></ColoredBubble>
   <ColoredBubble top="75rem" left="36%" rad="6"></ColoredBubble>

   <GrayBubble top="43rem" left="25%" rad="6"></GrayBubble>
   <GrayBubble top="80rem" left="15%" rad="7"></GrayBubble>
   <GrayBubble top="12rem" left="40%" rad="7.5"></GrayBubble>
   <GrayBubble top="36rem" left="65%" rad="8"></GrayBubble>
   <GrayBubble top="80rem" left="53%" rad="9"></GrayBubble>
   <GrayBubble top="20rem" left="85%" rad="3"></GrayBubble>
   <GrayBubble top="53rem" right="28%" rad="3"></GrayBubble>
   <GrayBubble top="80rem" right="8%" rad="3"></GrayBubble>
   <GrayBubble top="76rem" left="4%" rad="3"></GrayBubble>
   <GrayBubble top="105rem" left="55%" rad="3"></GrayBubble>


   {logoContent.map(({id, top, left, right, rad, className, imgClass, content, src}) => 
    <ImageBubble key={id} id={id} top={top} left={left ? left : ""} right={right ? right : ""} rad={rad} className={className}>
        <TechImage className="logos">
      <Tooltip title={content} placement="right-start">

          <img src={src} alt="" className={imgClass} />
      </Tooltip>

        </TechImage>
    </ImageBubble>
   )}
   

   {/* <ImageBubble id="socket" top="12rem" left="70%" rad="21"><TechImage className="logos"><img src={socket} alt="" className="tech-image" /></TechImage></ImageBubble>
   <ImageBubble id="graph" top="20rem" left="30%" rad="21"><TechImage className="logos"><img src={graph} alt="" className="tech-image" /></TechImage></ImageBubble>
   <ImageBubble id="nodejs" top="42rem" right="0" rad="21"><TechImage className="logos"><img src={nodejs} alt="" className="tech-image-bigger" /></TechImage></ImageBubble>
   <ImageBubble id="mongo" top="55rem" left="18%" rad="21"><TechImage className="logos"><img src={mongo} alt="" className="tech-image" /></TechImage></ImageBubble>
   <ImageBubble id="react" top="72rem" right="25%" rad="21"><TechImage className="logos"><img src={reactLogo} alt="" className="tech-image-bigger" /></TechImage></ImageBubble>
   <ImageBubble id="gatsby" top="85rem" left="30%" rad="21"><TechImage className="logos"><img src={gatsbyLogo} alt="" className="tech-image-bigger" /></TechImage></ImageBubble>
   <ImageBubble id="novo-logo" top="45rem" left="50%" rad="30" className="novo-logo"><TechImage className="logos"><img src={NovoInCircle} alt="" className="colored-cir" /></TechImage></ImageBubble> */}
   </ContainerAbs>
 </Container>
 )
}

export default Tech