import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  height: 30rem;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  margin: 0 2rem;
  padding: 3rem 2rem;
  box-shadow: 2px 5px 15px 5px #00000022;

  @media ${props => props.theme.breaks.down('md')} {
    height: 20rem;
    padding: 1rem 2rem;
  }
`
const Image = styled.div`
  width: 50%;
  /* background-color: #555; */
  /* border: 1px solid #828282; */
  min-width: 5rem;
  height: 95%;
  flex-grow: 1;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* margin: 1rem 0; */

  @media ${props => props.theme.breaks.down('md')} {
    width: 10rem;
  }

`

const Details = styled.div`
  margin-left: 4rem;
  padding-top: 2rem;
  width: 100%;
  flex-shrink: 1;

  @media ${props => props.theme.breaks.down('lg')} {
    margin-left: 2rem;
    padding-top: 1rem;
    min-width: 20rem;
  }

`

const Name = styled.h4`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.colors.heading3};

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 2.2rem;
  }
`

const Title = styled.h5`
  font-weight: normal;
  font-weight: 18px;
  color: ${props => props.theme.colors.darkText};
  margin-top: .5rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.6rem;
  }

`

const Description = styled.p`
  margin-top: 2rem;
  font-weight: 16px;
  font-style: italic;
  color: ${props => props.theme.colors.darkText};

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 1.4rem;
  }
`
 
export default ({name, title, description, img}) => {
 
 return (
  <Container>

    <Image img={img}>
      &nbsp;
    </Image>

    <Details>

    <Name>
      {name}
    </Name>

    <Title>
      {title}
    </Title>

    <Description>
      {description}
    </Description>

    </Details>

  </Container>
 )
}